/*eslint-disable */
<template>
  <div>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>
          <template v-slot:body>
            <p v-if="!propOpenedInModal">{{ cvCardSubHeader }}</p>
            <ValidationObserver ref="socialMediaReport">
              <form class="mt-3 iq-card p-3" @submit.prevent="onSubmit">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="validationmodule_type">{{ cvModuleTypeLabel }}</label><label class="primary-color font-size-16">*</label>
                  <select v-model="ModuleType" class="form-control" @change="setPlatformFilter" required>
                    <option value="" disabled>Choose Org Type</option>
                    <option v-for="(orgType, index) of cvUserTypesOptions" :key="(index+1)" :value="orgType.key">
                      {{orgType.value}}
                    </option>
                  </select>
                </div>
                <div class="col-md-12 mb-3" v-if="ModuleType && ModuleType.length > 0">
                  <label for="validationmodule_type">{{ cvModuleNameLabel }}
                    <span v-if="ModuleType == 'ORG'">Organisation </span>
                    <span v-if="ModuleType == 'SCH'">Shool </span>
                    <span v-if="ModuleType == 'UNIV'">University </span>
                  </label><label class="primary-color font-size-16">*</label>
                  <multiselect for="validationmodule_name"
                    v-model="module_data"
                    :options="univFiltersList['org_name , org_id']"
                    placeholder= "Select One"
                    track-by="org_id"
                    label="org_name"
                    class="mt-2"
                    @search-change="OrganisationsList($event)"
                    >
                    <span slot="noResult">No school found</span>
                  </multiselect>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationtopic">{{ cvTopicLabel }}</label><label class="primary-color font-size-16">*</label>
                  <ValidationProvider
                    :name="cvTopicLabel"
                    v-slot="{ errors }">
                  <input v-model="vmSocialMediaReportFormData.topic" type="text" class="form-control" required />
                    <span class="text-danger"> {{ errors[0]}} </span>
                </ValidationProvider>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationcreative_id">{{ cvCreativeIdLabel }}</label><label class="primary-color font-size-16">*</label>
                  <ValidationProvider
                    :name="cvCreativeIdLabel"
                    v-slot="{ errors }">
                  <input v-model="vmSocialMediaReportFormData.creative_id" type="text" class="form-control" required />
                  <span class="text-danger"> {{ errors[0]}} </span>
                  </ValidationProvider>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationplatform">{{ cvPlatformLabel }}</label><label class="primary-color font-size-16">*</label>
                  <ValidationProvider
                    :name="cvPlatformLabel"
                    rules="required"
                    v-slot="{ errors }">
                  <select class="form-control" v-model="vmSocialMediaReportFormData.platform">
                    <option v-for="(Social_platform, index) of SociaMediaPlatforms" :key="(index+1)" :value="index">{{ Social_platform }}</option>
                  </select>
                  <span class="text-danger"> {{ errors[0]}} </span>
                  </ValidationProvider>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationdeliverable_type">{{ cvDeliverableTypeLabel }}</label><label class="primary-color font-size-16">*</label>
                  <ValidationProvider
                    :name="cvDeliverableTypeLabel"
                    rules="required"
                    v-slot="{ errors }">
                      <select class="form-control" v-model="vmSocialMediaReportFormData.deliverable_type">
                        <option v-for="(DeliverableType, index) of DeliverableType" :key="index+'hosting_platform'" :value="index" >
                          {{ DeliverableType }}
                        </option>
                      </select>
                    <span class="text-danger"> {{ errors[0]}} </span>
                  </ValidationProvider>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationurl">{{ cvUrlLabel }}</label>
                  <input
                  v-model="vmSocialMediaReportFormData.url"
                  type="text"
                  class="form-control"
                  required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationreach">{{ cvReachLabel }}</label>
                  <input v-model="vmSocialMediaReportFormData.reach" type="number" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationimpression">{{ cvImpressionLabel }}</label>
                  <input v-model="vmSocialMediaReportFormData.impression" type="number" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationlikes">{{ cvLikesLabel }}</label>
                  <input v-model="vmSocialMediaReportFormData.likes" type="number" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationsaves">{{ cvSavesLabel }}</label>
                  <input v-model="vmSocialMediaReportFormData.saves" type="number" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationshares">{{ cvSharesLabel }}</label>
                  <input v-model="vmSocialMediaReportFormData.shares" type="number" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationcomments">{{ cvCommentsLabel }}</label>
                  <input v-model="vmSocialMediaReportFormData.comments" type="number" class="form-control" required />
                </div>
                 <!-- Upload Image -->
                 <div class="col-md-12">
                    <label>{{ cvSocialImageLable }} : {{ imageSize }} <span class="primary-color font-size-16">*</span></label>
                    <div class="form-group row align-items-center ml-1">
                        <div class="profile-img-edit border" style="height: 200px; width: 300px;">
                          <img v-if="vmCoverPic" :src="vmCoverPic" alt="profile-pic " style="height: 100%; width: 100%; object-fit: cover; overflow: hidden;" />
                          <i v-else class="fas fa-image d-flex justify-content-center align-items-center" style="font-size: 5rem; height:100%; color: #c1c1c1;"></i>
                          <label class="p-image">
                            <i class="ri-pencil-line upload-button" style="cursor: pointer;"></i>
                            <input class="file-upload" type="file" required accept="image/png, image/jpeg" id="fileInput1" style="position: absolute; opacity: 0; left: 0; top: 0; pointer-events: none;" @change="openImageDialog($event, 'Cover Pic')" />
                          </label>
                        </div>
                        <span class="text-danger" ref="coverPicError"></span>
                    </div>
                  </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                  <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="socialmediareportAdd()">
                  {{ cvSubmitBtn }}
                </button>
              </div>
            </form>
          </ValidationObserver>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <CropImage :prop-crop-image-dialog-open="propCropImageDialogOpen" :propCropModalSize="propCropDialogSize" :prop-stencil-size="propStencilSize" :propSrc="fileSrc" @emitCroppedImg="getCroppedImg" @emitCroppedImgFile="emitCroppedImgFile" />

    <b-toast v-model="showToast" name="Toast" :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css">
  .multiselect__select{
    top:5px;
  }
</style>
<script>
import { SocialMediaReports } from "../../../FackApi/api/socialmediareport"
import ApiResponse from "../../../Utils/apiResponse.js"
import SociaMediaPlatforms from "../../../FackApi/json/SocialMediaPlatform.json"
import DeliverableType from "../../../FackApi/json/DeliverableType.json"
import UserRoles from "../../../FackApi/json/UserRoles.json"
import { Organisations } from "../../../FackApi/api/organisation.js"
import { Images } from "../../../FackApi/api/image"
import CropImage from "../../../components/cropImage.vue"
import Multiselect from "vue-multiselect"
import userPermission from "../../../Utils/user_permission.js"
import { ValidationProvider } from "vee-validate"

export default {
  name: "SocialMediaReportAdd",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  components: {
    CropImage,
    Multiselect,
    ValidationProvider
  },
  data () {
    return {
      apiName: "socialmediareport_add",
      cvCardTitle: "Add socialmediareport",
      cvCardSubHeader: "Add socialmediareport",
      cvSubmitBtn: "Add",
      cvCreativeIdLabel: "Creative id",
      cvModuleTypeLabel: "Organization Type",
      cvModuleNameLabel: "Select Your",
      cvPlatformLabel: "Platform",
      cvUrlLabel: "Add URL",
      cvDeliverableTypeLabel: "Deliverable type",
      cvTopicLabel: "Topic",
      cvReachLabel: "Reach",
      cvImpressionLabel: "Impression",
      cvLikesLabel: "Likes",
      cvSavesLabel: "Saves",
      cvSharesLabel: "Shares",
      cvCommentsLabel: "Comments",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      DeliverableType: DeliverableType,
      SociaMediaPlatforms: SociaMediaPlatforms,
      UserRoles: UserRoles,
      toastTitle: "SocialMediaReport",
      vmSocialMediaReportFormData: Object.assign({}, this.initFormData()),
      vmSocialMediaReportDesc: null,
      vmSocialMediaReportType: null,
      univFiltersList: { "org_name , org_id": [] },
      module_data: {},
      ModuleType: "",
      cvSocialImageLable: "Social Media Image",
      imageSize: "Size  1080 * 566",
      vmCoverPic: "",
      propCropImageDialogOpen: false,
      propCropDialogSize: "md",
      propStencilSize: null,
      fileSrc: "",
      cvUserTypesOptions: [
        { key: "UNIV", value: "University" },
        { key: "ORG", value: "Organization" },
        { key: "SCH", value: "School" }
      ]
    }
  },
  async mounted () {
    if (!userPermission(this, this.userData, this.apiName)) {
      window.location.reload()
    }
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "creative_id": "",
        "module_name": "",
        "platform": "",
        "deliverable_type": "",
        "topic": "",
        "reach": "",
        "impression": "",
        "likes": "",
        "saves": "",
        "shares": "",
        "comments": ""
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmSocialMediaReportFormData) {
          if (!this.vmSocialMediaReportFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * socialmediareportAdd
     */
    async socialmediareportAdd () {
      // if (!this.validate()) {
      //   return false
      // }

      let valid = await this.$refs.socialMediaReport.validate()
      if (!valid) {
        return
      }

      try {
        this.cvLoadingStatus = true
        this.vmSocialMediaReportFormData.module_name = this.module_data.org_name
        this.vmSocialMediaReportFormData.module_id = this.module_data.org_id

        let socialmediareportAddResp = await SocialMediaReports.socialmediareportAdd(this, this.vmSocialMediaReportFormData)
        await ApiResponse.responseMessageDisplay(this, socialmediareportAddResp)
        if (socialmediareportAddResp.resp_status) {
          if (socialmediareportAddResp && !socialmediareportAddResp.resp_status) {
            return false
          }
          if (socialmediareportAddResp.resp_data && socialmediareportAddResp.resp_data.smr_id) {
            await this.imageAdd(socialmediareportAddResp.resp_data.smr_id)
          }

          //  Emit if opened in Modal
          if (this.propOpenedInModal) {
            this.$emit("emitCloseSocialMediaReportAddModal", socialmediareportAddResp.resp_data)
          }
          this.vmSocialMediaReportFormData = Object.assign({}, this.initFormData())
        }
        else {
          this.toastMsg = socialmediareportAddResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at socialmediareportAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /*
    * univFilterList
    */
    async univFilterList (filterName = null) {
      if (!filterName) {
        return
      }
      let selectedType = this.ModuleType

      try {
        let univFilterListResp = await Organisations.organisationFilterList(this, selectedType, filterName)
        if (univFilterListResp) {
          this.univFiltersList[filterName] = univFilterListResp.resp_data
        }
      }
      catch (err) {
        console.error("Exception occurred at univFilterList() and Exception:", err.message)
      }
    },
    /**
     * setPlatformFilter
     */
    setPlatformFilter () {
      this.univFilterList("org_name , org_id")
    },
    /**
     * getCroppedImg
     */
    getCroppedImg (img) {
      if (this.updateImageType === "Profile Pic") {
        this.vmProfilePic = img
      }
      else {
        this.vmCoverPic = img
      }
    },
    /**
     * openImageDialog
     */
    openImageDialog (e, imageType) {
      this.updateImageType = imageType
      if (e.target.files.length > 0) {
        this.fileSrc = e.target.files[0]
      }
      let input = e.target
      if (input.files.length > 0 && input.files[0]) {
        let image = input.files[0]
        let allowedExtension = [ "image/png", "image/jpg", "image/jpeg", "image/webp" ]
        let maxSize = 300 * 1024 // 300 KB in bytes
        if (!allowedExtension.includes(image.type)) {
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          return false
        }
        else if (image.size > maxSize) {
          // validate image size
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.toastVariant = "danger"
            this.showToast = true
          }
          return false
        }
        this.propStencilSize = {
          "width": 540,
          "height": 283
        }
        this.propCropDialogSize = "md"
        this.propCropImageDialogOpen = Math.random()
      }
    },
    /**
     * emitCroppedImgFile
     */
    emitCroppedImgFile (imgObj) {
      let img = imgObj.imgFile
      let smrImg = imgObj.orgImage
      let imageType = this.updateImageType
      this.onChangeProfileImage(img, imageType)
      this.vmPBACoverPicImage = Object.assign({}, this.initImageData())
      this.vmPBACoverPicImage.image = {
        "img": img,
        "name": smrImg.name
      }
      this.vmPBACoverPicImage.image_type = "cover_pic"
    },
    /**
     * onChangeProfileImage
     */
    onChangeProfileImage (e, imageType) {
      try {
        const reader = new FileReader()
        reader.onload = async (e) => {
          if (imageType === "Profile Pic") {
            this.vmProfilePic = await reader.result
          }
          else {
            this.vmCoverPic = await reader.result
          }
        }
        // reader.readAsDataURL(image)
      }
      catch (err) {
        console.error("Exception occurred at onChangeProfilePic() and Exception:", err.message)
      }
    },
    /**
     * initImageData
     */
    initImageData () {
      return {
        image: "",
        image_type: ""
      }
    },
    /**
     * imageAdd
     */
    async imageAdd (smrId) {
      if (this.cvImageError) {
        return
      }
      try {
        this.cvImageLoadingStatus = true
        let imageObj = {
          "module_name": "SMR",
          "module_id": smrId,
          "image": []
        }

        if (this.vmPBACoverPicImage.image) {
          imageObj.image.push(this.vmPBACoverPicImage)
        }

        if (imageObj.image.length < 1) {
          return false
        }

        const imageUpdateResp = await Images.imageProfileAdd(this, imageObj)
        await ApiResponse.responseMessageDisplay(this, imageUpdateResp)
        if (imageUpdateResp && !imageUpdateResp.resp_status) {
          return false
        }

        this.vmPBACoverPicImage = Object.assign({}, this.initImageData())
        imageObj = {}
        return imageUpdateResp
      }
      catch (err) {
        console.error("Exception occurred at imageAdd() and Exception:", err.message)
        return err
      }
      finally {
        this.cvImageLoadingStatus = false
      }
    },
    /**
     * OrganisationsList
     */
    async OrganisationsList (searchString) {
      let selectedType = this.ModuleType

      if (!searchString || searchString.trim().length < 1) {
        this.cvSchoolOptions = [ ...this.cvSchoolOptionsBackup ]
        return
      }

      if (searchString.length < 3) {
        return
      }

      if (searchString.toLowerCase() === "school" || searchString.toLowerCase() === "schoo" || searchString.toLowerCase() === "schoo" || searchString.toLowerCase() === "sch") {
        this.toastMsg = "Please enter your Orgnisation name."
        this.toastVariant = "danger"
        this.showToast = true
        return
      }

      const filter = {
        // limit: this.schLimit,
        search: searchString.trim(),
        type: [selectedType]
      }

      let schoolResp = await Organisations.organisationListSearch(this, filter)
      if (schoolResp.resp_status) {
        // this.cvSchoolOptions = schoolResp.resp_data.data
        this.cvSchoolOptions = Object.keys(schoolResp.resp_data.data).map((key) => schoolResp.resp_data.data[key])
      }
    }
  }
}
</script>
