/*eslint-disable */
// import SocialMediaReportModel from '../../Model/SocialMediaReport'
import request from '../../Utils/curl'

let SocialMediaReports = {
  /**
   * socialmediareportList
   */
  async socialmediareportList (context, whereFilter=null) {
    try{
      let post_data = new FormData();

      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "socialmediareport_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at socialmediareportList() and Exception:',err.message)
    }
  },

  /**
   * socialmediareportView
   */
  async socialmediareportView (context, moduleId) {
    try {      
      let post_data = new FormData();
      post_data.append('module_id', moduleId);
      return await request.curl(context, "socialmediareport_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at socialmediareportView() and Exception:",err.message)
    }    
  },

  /**
   * socialmediareportAdd
   */
  async socialmediareportAdd (context, socialmediareportObj) {
    try{
      let post_data = new FormData();
    
      for (let key in socialmediareportObj) {
        post_data.append(key, socialmediareportObj[key]);
      }

      return await request.curl(context, "socialmediareport_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at socialmediareportAdd() and Exception:',err.message)
    }
  },

  /**
   * socialmediareportEdit
   */
  async socialmediareportEdit (context, socialmediareportObj) {
    try{
      let post_data = new FormData();
    
      for (let key in socialmediareportObj) {
        post_data.append(key, socialmediareportObj[key]);
      }

      return await request.curl(context, "socialmediareport_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at socialmediareportEdit() and Exception:',err.message)
    }
  },

  /**
   * socialmediareportDelete
   */
  async socialmediareportDelete (context, moduleId) {
    try{
      let post_data = new FormData();
      
      post_data.append('module_id', moduleId);

      return await request.curl(context, "socialmediareport_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at socialmediareportDelete() and Exception:',err.message)
    }
  },

  /**
   * socialmediacountreportList
   */
  async socialmediacountreportList (context, whereFilter=null) {
    try{
      let post_data = new FormData();

      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "socialmediareport_count", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at socialmediareportList() and Exception:',err.message)
    }
  },
}

export {
  SocialMediaReports
}
